body.et_divi_builder #et_builder_outer_content .et_pb_section {

	// Default Text Module Styling (needed on all sites)
	.et_pb_text {
		ul,ol {
			text-align: left;
			margin: 1.5em 0 1em;
		}

		p.large.left {
			text-align: left !important;
			max-width: 320px !important;
			padding-left: 1.3em !important;

			i {
				font-style: normal !important;
				margin-left: -1.4em !important;
    		margin-right: 0.4em !important;
			}
		}
	}

}
