.site {
	// Text Inputs
	input[type="text"],
	input[type="password"] {
		width: 100%;
		border: 2px solid rgba(#859193, 0.6);
		color: #859193;
		background: white;
		padding: 0.4em 1em !important;
		border-radius: 0;
		height: inherit;
	}
	textarea {
		width: 100%;
		border: 2px solid rgba(#859193, 0.6);
		color: #859193;
		background: white;
		padding: 0.4em 1em !important;
		border-radius: 0;
	}
	select {
		width: 40%;
		border: 2px solid rgba(#859193, 0.45);
		border-radius: 0;
		color: #667081;
		padding: 0.7em 1em !important;
		font-size: 19px;
		margin-top: 0px;
		height: inherit;
		-webkit-appearance: none;
		background: url(/wp-content/themes/boomerangkids/images/logos/dropdown-arrow.svg) no-repeat 100%;
		background-size: 48px;
		line-height: 1;
	}

	@media only screen and (max-width: 768px) {
		select {
			width:85%;
		}
	}

	input[type="submit"] {
		cursor: pointer;
		@include transition;
	}

	input.hidden {
		display:none !important;
	}

	.mailing-list-wrapper {
		input[type="email"] {
			background: transparent;
			border: 0px !important;
			border-bottom: 1px solid $color-third !important;
			padding-bottom: 5px;
		}

		button {
			background-color: transparent !important;
			border: 0px !important;
			padding: 0px;
			box-shadow: none;

			.arrow {
				max-width: 30px;
				min-width: 30px;
				margin-top: .8em;
			}

			#{$hover} {
				color: $color-third !important;
				padding: 0px;
			}
		}

		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $grey-blue;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: $grey-blue;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: $grey-blue;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: $grey-blue;
		}

		.vertical {
			label {
				@include font-size(12px);
				font-family: $fonts-head !important;
	      		font-weight: 600 !important;
	      		line-height: 26px;
	      		display: block;
			}

			input[type="email"] {
				display: block;
				width: 100%;
			}

			button {
				display: block;
			}
		}

		.error {
			padding-bottom: 0.5em;
		}
	}

	.mailing-list-wrapper.footer,
	.mailing-list-wrapper.student {
		border: 1px solid $color-third !important;
		border-radius: 30px !important;

		form {
			display: flex;
			align-items: center;
		}

		input[type="email"] {
			margin: 0 2em !important;
			width: 200px !important;
		}

		.success {
			padding: 1em 0 !important;
			margin: 0 auto !important;
		}
	}

	.mailing-list-wrapper.student {
		padding: 0.5em 2em !important;
		max-width: 560px;
		margin: 0 auto !important;

		label,
		.success {
			@include font-size(11px);
			font-family: $fonts-head !important;
      		font-weight: 700 !important;
			color: $color-third !important;
      		text-transform: uppercase;
		}

		.success {
			margin: 0 auto !important;
		}

		form {
			margin: 0 auto !important;
		}
	}

	#et_builder_outer_content .et_pb_section p.success {
		@include font-size(11px);
		color: $color-third !important;
		text-align: center;
	}



	.mailing-list-wrapper.footer {
		padding-left: 2em;
		padding-right: 2em;

		label {
			@include font-size(12px);
      		font-weight: 500;
			color: white;
			text-align: center;
		}

		input[type="email"] {
			color: white !important;
		}
	}

}
