// Fonts
@import 'https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700';

$a: 'a, a:link, a:visited';
$hover: '&:hover, &:focus';

$fonts-main: 'Barlow', sans-serif;
$fonts-head: 'Barlow', sans-serif;

$fonts-icon: 'ETmodules';

@font-face {
  font-family: 'icomoon';
  src:  url('/wp-content/themes/boomerangkids/fonts/icomoon.eot');
  src:  url('/wp-content/themes/boomerangkids/fonts/icomoon.eot?nbvgx1#iefix') format('embedded-opentype'),
    url('/wp-content/themes/boomerangkids/fonts/icomoon.ttf') format('truetype'),
    url('/wp-content/themes/boomerangkids/fonts/icomoon.woff') format('woff'),
    url('/wp-content/themes/boomerangkids/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}



$headings: 'h1, h2, h3, h4, h5, h6';
