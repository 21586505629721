.btn,
body button:not(.mfp-close),
body.et_divi_builder #et_builder_outer_content .et_pb_section .et_pb_button,
body.et_divi_builder #et_builder_outer_content .et_pb_section .btn,
body .gform_footer input[type="submit"] {


	// @MIXIN:
	// button(buttonColor, buttonHoverColor, textColor, textHoverColor, borderRadius)
	@include button($button-color, $button-hover-color, $color-fourth, $color-secondary, 20px);

	@media only screen and (min-width: 1001px) {
		@include font-size(16px);
  	}
  	
	@include transition();
	line-height: 1.7em !important;

	text-transform: uppercase;

	letter-spacing: 0.1em !important;

	border: 1px solid $button-color;
	box-shadow: 0 0 30px 5px rgba(0,0,0,0.1);

	cursor: pointer;
	font-family: $fonts-head;
	-webkit-appearance: none;

	#{$hover} {
		border: 1px solid $color-fourth;
	}

	&.prev {
		@include reverse-button-icon();
	}

	&.all, &.bis-load-more {
		@include no-button-icon();
	}
	&.bis-load-more .etmodules {
		padding-left: 0.5em;
	}

	// &:after {
	//   content: none !important;
	// }

	&:active {
		#{$hover} {
		}
	}
}

// White Inverse
body button.inverse,
body.et_divi_builder #et_builder_outer_content .et_pb_section .et_pb_button.inverse,
body.et_divi_builder #et_builder_outer_content .et_pb_section .btn.inverse {

	// @MIXIN:
	// button(buttonColor, buttonHoverColor, textColor, textHoverColor, borderRadius)
	@include button(transparent, $color-main, $color-main, $color-third, 20px);
	@include font-size(16px);
	@include transition();
	padding: 1.2em 2em;

	text-transform: uppercase;

	letter-spacing: 0.1em !important;

	border: 2px solid $color-main;
	box-shadow: none;

	cursor: pointer;
	font-family: $fonts-head;
	-webkit-appearance: none;

	#{$hover} {
		border: 2px solid $color-main;
		padding: 1.2em 2em;
	}

	@media only screen and (max-width: 768px) {
		font-size: 0.75rem;
	}
}
