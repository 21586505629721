// Form styling

.gform_confirmation_message {
  text-align: center !important;
  @include font-size(20px);
  margin-bottom: 1em !important;
}

// Form styling
body #et_builder_outer_content .gform_wrapper form {
  border: none;
  padding: 1em 0;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 999px) {
    max-width: none;
  }

  h3 {
    font-size: 45px;

    &:before {
      content: '';
    }
  }

  .validation_error,
  .validation_message {
    color: red;
  }

  .gform_body {
    $placeholder-color: #ACA2AD;
    $placeholder-font-size: 24px;
    $placeholder-font-weight: 200;
    ::-webkit-input-placeholder {
       color: $placeholder-color;
       font-size: $placeholder-font-size;
       font-weight: $placeholder-font-weight;
    }
    :-moz-placeholder { /* Firefox 18- */
       color: $placeholder-color;
       font-size: $placeholder-font-size;
       font-weight: $placeholder-font-weight;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
       color: $placeholder-color;
       font-size: $placeholder-font-size;
       font-weight: $placeholder-font-weight;
    }
    :-ms-input-placeholder {
       color: $placeholder-color;
       font-size: $placeholder-font-size;
       font-weight: $placeholder-font-weight;
    }

    .gform_fields {
      display: flex;
      flex-wrap: wrap;

      .gfield {
        width: 100%;
      }
      .gfield.one-half {
        width: 47.5%;

        &:nth-child(2n) {
          margin-left: 5% !important;
        }
      }

      @media only screen and  (max-width: 768px) {
        .gfield.one-half {
          width: 100%;
        }
        .gfield.one-half:nth-child(2n) {
          margin-left: 0% !important;
        }
      }
    }

    .gfield .gfield_label {
      display: none;
    }

    .ginput_container_name {
      display: flex;
      width: 100%;

      .span {
        width: calc(48% - 2em);
      }
      .name_first {
        margin-right: 2em;
      }

      label {
        display: none;
      }
    }

    .ginput_container {
      padding-bottom: 1.4em;

      &.ginput_container_textarea {
        padding-bottom: 0em;
      }
      @media only screen and  (max-width: 768px) {
        padding-bottom: 0.6em;
      }
    }

    .ginput_container_address {
      span {
        margin-bottom: 0.8em;
      }
      .ginput_full input {
        margin-bottom: 0.8em;
      }
      .address_city {
        padding-right: 8px!important;
      }
    }

    .ginput_recaptcha {
      padding-bottom: 0.4em;
    }

    ul li:before {
      display: none !important;
    }

    input[type="text"],
    input[type="password"],
    textarea {
      width: 100%;
      border: 1px solid #EAE8EA;
      border-radius: 20px;
      box-shadow: 0 0 30px 2px rgba($black, .1);
      background: $white;
      font-size: 24px;
      color: $grey;
      padding: 1.3em 1.1em !important;
      height: 100px;
    }
    select {
      width: 100%;
      border: 1px solid #EAE8EA;
      border-radius: 20px;
      color: $grey;
      box-shadow: 0 0 30px 2px rgba($black, .1);
      padding: 1.3em 1.1em !important;
      height: 100px;
      font-size: 24px;
      margin-top: 0px;
      -webkit-appearance: none;
      -webkit-border-radius: 20px;
      background: url(/wp-content/themes/boomerangkids/images/logos/dropdown-arrow.svg) no-repeat right center;
      background-size: 48px;
      background-color: $white;
    }
    textarea {
      height: 350px;
    }

    @media only screen and (max-width: 999px) {
      text-align: left;

      input[type="text"],
      input[type="password"],
      textarea {
        box-shadow: 0 0 30px 2px rgba($black, .1);
        font-size: 16px;
        padding: 0.4em 1em !important;
        height: 80px;
      }
      select {
        box-shadow: 0 0 30px 2px rgba($black, .1);
        padding: 0.4em 1em !important;
        height: 80px;
        font-size: 16px;
        background-size: 48px;
      }
      textarea {
        height: 350px;
      }

      ::-webkit-input-placeholder {
         font-size: 16px;
      }
      :-moz-placeholder { /* Firefox 18- */
         font-size: 16px;
      }
      ::-moz-placeholder {  /* Firefox 19+ */
         font-size: 16px;
      }
      :-ms-input-placeholder {
         font-size: 16px;
      }
    }

  }

  .gform_footer {
    text-align: center;
    margin-top: 1em;

    input[type="submit"] {
      // @MIXIN:
    	// button(buttonColor, buttonHoverColor, textColor, textHoverColor, borderRadius)
    	@include button($button-color, $button-hover-color, $color-fourth, $color-main, 20px);
    	@include font-size(16px);
    	@include transition();
      padding: 1.3em 1.8em;

    	text-transform: uppercase;

    	letter-spacing: 0.1em !important;

    	border: 1px solid $button-color;
    	box-shadow: 0 0 30px 10px rgba(0,0,0,0.1);

    	cursor: pointer;
    	font-family: $fonts-head;
    	-webkit-appearance: none;

    	#{$hover} {
    		border: 1px solid $color-third;
        padding: 1.3em 1.8em;
    	}

    	&:after {
        content: none !important;
      }

    	&:active {
    		#{$hover} {
    		}
    	}

      @media only screen and (max-width: 999px) {
        @include font-size(11px);

        text-align: center;
        padding: .9em 1.8em;

        #{$hover} {
          border: 1px solid $color-third;
          padding: .9em 1.8em;
        }
      }
    }
  }
}
