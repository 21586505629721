/*
 * Team Member Template Styling
 * - Default Picture Shows at first
 * - Hover reveals name, title, bio, and more links
*/
.et_divi_builder #et_builder_outer_content .featured-team-member {
  position: relative;
  min-width: 22.0%;
  max-width: 22.0%;
  margin: 0px 40px 25px 0px;
  flex: 1;

  @include stupidSafariFlex(1,1,22%);

  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  .featured-image {
    position: relative;
    max-width: 100%;
    min-height: 270px;
    cursor: pointer;
    margin-bottom: 1em;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;

    &:hover {
      .team-open {
        opacity: 1;
      }
    }
  }

  .team-open {
    @include transition();
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    background: rgba(#071C2C, 0.8);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .featured-title p {
    padding-bottom: .2em;
  }
  .featured-content {
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba($color-fourth, 0.9);
    display: none;
    justify-content: center;
    align-items: center;
    padding: 2em;

    h2 {
      max-width: none;
    }
    h2:after {
      content: '';
    }
    h5 {
      margin-bottom: 0.5em;
    }
    i, a {
      color: $color-third;
      font-style: normal;

      &:hover {
        color: $color-third;
      }
    }
    p {
      font-weight: 400;
    }
  }

  .featured-content-wrapper {
    padding: 3em 2em;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    background-color: $white;
    display: flex;
    position: relative;
  }
  .featured-content-wrapper .featured-image {
    min-width: 400px;
    height: 400px;
    margin-right: 2em;
  }

  .featured-excerpt p {
    @include large-paragraph();
    margin-top: 2em;
    max-width: none !important;
  }

  .featured-content .featured-links {
    bottom: 1.5em;
    position: absolute;

    a {
      margin-right: 0.5em;

      #{$hover} {
        color: $color-secondary;

        i {
          color: $color-secondary;
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    .featured-content-wrapper {
      flex-wrap: wrap;
      justify-content: center;
    }
    .featured-excerpt {
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
    }
    .featured-content-wrapper .featured-image {
      margin-right: 0;
    }
  }

  // Close button
  .close-team {
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    line-height: 50px;
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 30px;
    z-index: 9999;

    &:before, &:after {
        $width: 35px;
        $height: 3px;
        transform: rotate(-45deg);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$height/2;
        margin-left: -$width/2;
        display: block;
        height: $height;
        width: $width;
        background-color: $color-third;
        border-radius: 5px;
        transition: all 0.25s ease-out;
    }
    &:after {
        transform: rotate(-135deg);
    }
    &:hover {
        &:before, &:after {
            transform: rotate(0deg);
            background-color: $color-third;
        }
    }

    @media only screen and (max-width: 768px) {
      top: 5px;
      right: 5px;
    }
  } // End close button


  @media only screen and (max-width: 1155px) {
    min-width: calc(50% - 1.3em);
    max-width: calc(50% - 1.3em);
    flex: 1 1 calc(50% - 1.3em);
    margin-bottom: 2em;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 650px) {
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 100%;

    margin-right: 0;
  }
}
    /*
     & WRAPPER - Team Member Wrapper Styling
     & - Has no max width and no padding
     &
    */
    .et_divi_builder #et_builder_outer_content .et_pb_section .featured-team-member-wrapper {
      padding: 0 35px;
      margin-bottom: 2em;

      .et_pb_text:first-child {
        margin-bottom: 1em;
      }

      .et_pb_text_inner {
        display: flex;
        flex-wrap: wrap;
      }
    }


/*
 * Articles Homepage
 * -
*/
.et_divi_builder #et_builder_outer_content .featured-articles {

  .et_pb_text_inner {
    display: flex;
    flex-wrap: wrap;
  }

  .archive-item {
    margin-right: 3em;
    display: block;
    position: relative;
    width: calc(33.33% - 6em/3);
    padding-top: 1.2em;
    border-top: 1px solid $color-third;
  }
  .archive-item:nth-child(3n + 3) {
    margin-right: 0;
  }
  .archive-item > a {
    min-width: 100%;
    height: inherit;
  }
  .archive-item .thumb-image {
    min-width: 100%;
    height: 280px;
    background-size: cover;
    margin-bottom: 1.5em;
  }
  .archive-item .featured-meta {
    @include font-size(14px);
    color: $color-third;
    margin-bottom: .8em;
  }
  .archive-item .featured-excerpt {
    margin-bottom: .8em;
  }
  .archive-item .event-links a {
    @include font-size(14px);
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    .archive-item {
      width: 100%;
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
}
