//
// @file     custom-elements/_bubble-sections.scss
// @parent   _divi.scss
//
// @desc     Styles bubbles
// @usage    First-leve pages like 'Career Path' and 'Services'
//
//


// Services bubble list
.et_pb_section.services-bubbles {
  padding-top: 0 !important;

  .et_pb_row.bordered-row {
    max-width: none;
    position: absolute;
    padding: 0;
    top: 146px;
    margin: 0;
    border-bottom: 1px solid rgba($color-third, 0.8);
  }
  .et_pb_row.bubbles .et_pb_column {
    background-color: $white;
    cursor: pointer;
    position: relative;

    .et_pb_image {
      z-index: 2;
      background-color: $white;
      position: relative;
    }

    .et_pb_code {
      display: none;
      animation: fadeIn 1s ease-in;
    }

    .the-line {
      height: 100px;
      width: 2px;
      border-left: 1px solid $color-third;
      position: absolute;
      left: 50%;
      top: -50px;
      z-index: 1;
    }
  }

  .et_pb_image.active {
    background-color: #F6F8F9;
    border-radius: 50%;
  }
  .et_pb_image.active + .et_pb_code {
    display: block !important;
  }

  ul {
    margin: 0 ;
  }

  @media only screen and (max-width: 999px) {
    display: none;
  }

  .et_pb_row.accordion {
    margin-top: 2em;
    background-color: #F6F8F9;
    border: 1px solid $color-third;
    border-radius: 20px;
    padding: 2em;

    li {
      color: $color-text;
    }
  }
}
