header {
	// CSS Transitions
	-webkit-transition: all 0.4s ease-out;
 	-moz-transition: all 0.4s ease-out;
 	transition: all 0.4s ease-out;

	// Mixins
	@include position(fixed,0,0,auto,0);
	@include font-size(20px);

	max-width: 1440px;
	margin: 0 auto;

	@media all and (-ms-high-contrast: none) and (min-width: 1440px), (-ms-high-contrast: active) and (min-width: 1440px) {
		left: calc(100%/2 - 1440px/2);
		margin: 0;
	}

	// Styling
	z-index: 999;
	background-color: $header-color;

	// Elements styling
	a {
		font-weight: 400;
		display: inline-block;
		color: $header-link-color;

		#{$hover} {
			color: $header-link-hover;
		}
	}
	img {
		display: block;
		width: 320px;
		height: auto;
	}
	ul {
		margin: 0;
		padding: 0;
		height: inherit;
		transition: all 0.4s ease-out;

		> li {
			height: 100%;
			display: inline-flex;
			align-items: center;
			position: relative;
			vertical-align: middle;
			margin: 0 1em;
		}

		@media only screen and (min-width: 1000px) and (max-width: 1200px) {
			> li {
				margin: 0 1em 0 0;
			}
		}
	}
	nav {
		height: 100%;
	}

	// Class Styling
	.logo-link {
		height: 100%;
		width: inherit;

		#nav-logo {
			width: 230px;
			padding: 5px 5px;
			transition: all 0.2s ease-out;

			@media only screen and (min-width: 1000px) and (max-width: 1260px) {
				width: 190px !important;
				margin-top: 20px !important;
			}
			@media only screen and (max-width: 999px) {
				width: auto !important;
				height: 70px !important;
				padding: 8px 0;
				margin-top: 0px !important;
			}
		}

		@media only screen and (min-width: 1000px) and (max-width: 1260px) {
			// width: 40px;
		}
	}

	// Sticky header styles
	&.is-sticky {
		background: white;
		box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

		ul {
			height: 60px;
		}
		.bottom-menu {
			height: 60px;
		}
		.logo-link {
			#nav-logo {
				display: none;

				@media only screen and (max-width: 999px) {
					display: block;
				}
			}
		}

		nav.main-navigation {
			align-items: center;

			.header-left-menu a span.text {
				width: 0px;
				overflow: hidden;
			}

			.header-right-menu .language-switcher {
				animation-name: touchMeStupid;
          		animation-iteration-count: 1;
          		animation-delay: 0.4s; 
          		animation-duration: 0.1s; 
          		animation-direction: normal;
			}
		}

		nav.main-navigation ul.sub-menu {
			top: 60px;
		}

		@media only screen and (max-width: 999px) {
			box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
		}
	}

	@media only screen and (max-width: 999px) {
		& > ul {
			height: 70px !important;
		}
		.bottom-menu {
			height: 70px !important;
		}
		.logo-link {
			// #nav-logo {
			// 	width: 300px;
			// 	margin-top: 10px;
			// }
		}
	}

	#menu-header-center-menu, 
	#menu-header-center-menu-french {
		> li {
			padding: 6px 0 12px;
		}
	}

	// Navigation Link Styling
	nav.main-navigation {
		width: 100%;
		margin: 0 auto;
		text-align: right;
		display: flex;
		justify-content: stretch;
		align-items: flex-start;

		@media only screen and (min-width: 1001px) {
			display: flex !important;
		}

		// Menu links
		ul {
			text-align: center;
		}
		ul:not(.sub-menu) > li > a {
			font-weight: 500;
			display: flex;
			flex-wrap: wrap;
			letter-spacing: 0.1em;
			padding-top: 2px;
			@include font-size(14px);
			@include transition(.4s);
			text-transform: uppercase;
		}
		ul li.current-menu-item > a,
		ul li.current-page-ancestor > a {
			color: $header-link-hover;
		}
		//
		// SUB MENU STYLING
		// General Sub Menu item styling
		//
		ul.sub-menu {
			@include transition();
			display: none;
			height: auto !important;
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: auto !important;
			}
			top: 36px;
			width: 200px;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			background: $white;
			box-shadow: 0 0 30px 2px rgba($black, .1);
			border-top: none;
			border-radius: 20px;
			padding: .5em 2em;

			&:after {
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(255, 255, 255, 0);
				border-bottom-color: #FFFFFF;
				border-width: 10px;
				margin-left: -10px;
			}

			li {
				margin: .5em 0;
				display: block;
				height: auto;
				align-items: flex-start;
			}

			li a {
				@include font-size(12px);
				display: flex;
				justify-content: center;
				color: $black;
				text-transform: uppercase;
				font-weight: 300;
				letter-spacing: 0.1em;
			}

			li a:hover {
				color: $color-third;
			}

			//Spacing lines between drop-down menu items
			li:not(:first-of-type) {
				margin-top: 15px;

				&:before {
				  	font-family: 'icomoon';
				  	content: '\e900';
				  	font-size: 1.5px;
				  	display: block;
				  	color: $header-dropdown-spacer;
				  	margin-top: 0px;
				    margin-bottom: 10px;
				}
			}
		}
		ul li.menu-item-has-children:hover .sub-menu {
			display: block;
		}

		.header-left-menu {
			@include font-size(12px);
			text-transform: uppercase;
			display: flex;
			flex-wrap: wrap;
			flex: 1;
			min-width: 200px;
			padding: 1em 0;

			.item {
				display: flex;
				align-items: center;
				margin-right: 1em;
				margin-bottom: 0.5em;
				transition: visibility 0s, opacity 0.5s linear;

				@media only screen and (min-width: 1001px) and (max-width: 1440px) {
					.text {
						display: none;
					}
				}

				&.active {
					color: $purple;

					i {
						color: $purple;
					}
				}
			}

			a {
				color: $grey;
			}

			i {
				@include font-size(20px);
				margin-right: .5em;
				color: $input-placeholder;
			}
		}
		.header-center-menu {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			flex: 6;

			@media only screen and (min-width: 1440px) {
				min-width: 680px;
				flex: 6;
			}

			@media only screen and (max-width: 1000px) {
				justify-content: flex-start;
			}

			.mobile-pullout {
				width: 100%;

				@media only screen and (min-width: 1001px) {
					.socials,
					.header-left-menu,
					.menu-social-menu-container,
					.menu-social-menu-french-container {
						display: none;
					}
				}
			}
		}
		.header-right-menu {
			flex: 1;
			min-width: 100px;
			max-width: 40%;
			padding: 1em 0;
			@include font-size(12px);
			text-transform: uppercase;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media only screen and (max-width: 1000px) {
				height: 100%;
				padding: 0;
			}

			.menu li a {
				padding-top: 0px;
			}

			i {
				@include font-size(20px);
				margin-right: .5em;
				color: #ACA2AD;
			}

			.language-switcher {
				position: relative;
				z-index: 12;
				color: $color-third;
				border: solid 1px $color-third;
				border-radius: 5px;
				font-weight: bold;
				padding: 0.4em 1em;
				margin-left: 1.5em;
				margin-bottom: 4px;

				@media only screen and (min-width: 1201px) and (max-width: 1440px) {
					margin-left: 0.5em;
				}
				@media only screen and (max-width: 1200px) {
					margin-left: 0;
				}
			}

			.language-switcher:hover {
				color: $color-third-hover;
				border-color: $color-third-hover;
			}
		}

		//
		// SUB MENU STYLING
		// General Sub Menu item styling
		//
	}



	.bottom-menu {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0px 35px;
		margin: 0 auto;

	 	transition: all 0.4s ease-out;

		@media only screen and (max-width: 1100px) {
			padding: 0px 15px;
		}
	}
	z-index: 999;
}
