//
// @file     _divi-customizing.scss
// @parent   main.scss
//
// @desc     Styles Divi row/section/columns
// @usage
//
//
body.et_divi_builder #et_builder_outer_content {

  // Mobile Reversing Classes (needed on all sites)
  .et_pb_section {
    @media only screen and (max-width: 999px) {
      &.mobile-reverse .et_pb_row {
        display: flex;
        flex-direction: column-reverse;
      }
      .mobile-reverse.et_pb_row {
        display: flex;
        flex-direction: column-reverse;

        .et_pb_column {
          margin-bottom: 30px !important;
        }
      }
    }

    .et_pb_image.rounded-img img {
      border-radius: 50%;
    }
    img.rounded-img {
      border-radius: 50%;
    }
  }

  h3.phone {
    font-size: 30px;
    font-weight: 300;

    i {
      margin-right: .5em;
      color: $color-third;
    }

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  h3.mail {
    font-size: 30px;

    i {
      margin-right: .5em;
      color: $color-third;
    }

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .et_pb_section.border-bottom {
    border-bottom: 1px solid $color-third;

    .et_pb_row {
      padding: 1.1em 0;
    }

    @media only screen and (min-width: 1000px) {
      margin: 0 3em;
    }
    @media only screen and (max-width: 999px) {
      margin: 0 25px;
    }
  }

  .et_pb_column.border-top {
    border-top: 1px solid $color-third;
    padding-top: 1em;
  }

  .arrow-svg img {
    @include transition();
    width: 30px;
    margin-top: .1em;

    #{$hover} {
      margin-left: .5em;
    }
  }

  .row-flex {
    display: flex;

    & > .column-flex:first-child {
      margin-right: 3em;
    }
  }

  .et_pb_section.grey {
    background-color: #F6F8F9;
  }

  .contact-links {
    display: flex;
    justify-content: center;

    a {
      margin: .8em 1em;
    }
  }

  @import 'custom-elements/aligned-half-section';
  @import 'custom-elements/image-text-block';
  @import 'custom-elements/team-member';
  @import 'custom-elements/narrow-section';
  @import 'custom-elements/pre-footer';
  @import 'custom-elements/bubble-sections';

  @import 'modules/toggle';
  @import 'modules/blockquote';
}
