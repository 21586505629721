body .event-wrapper {
  display: flex;
  padding: 3em;
  border-top: 1px solid $grey-blue;

  h2 {
    max-width: none;

    &:after {
      content: '';
    }
  }

  // Sidebar Styling
  .post-sidebar {
    min-width: 300px;
    margin-right: 3em;
    height: inherit;
  }
  .post-sidebar .sidebar-item {
    margin-bottom: 2em;
  }
  .sidebar-item .filter-item {
    margin-bottom: 2em;
  }
  .sidebar-item .filter-item a {
    color: $color-p;
    font-weight: 100;
    margin-bottom: 1em;
  }
  .sidebar-item .filter-item li {
    margin-bottom: .7em;
  }
  .sidebar-item .filter-item h3 {
    margin-bottom: .4em;

    .eyebrow {
      color: $color-fourth !important;
    }
  }

  // News Post Styling
  .post-cms .post-meta {
    @include font-size(16px);
    font-family: $fonts-head;
    font-weight: 300;
  }
  .post-cms .featured-image {
    height: 670px;
    background-position: center;
    background-size: cover;
    margin: 2em 0;
  }
  .post-cms p {
    @include large-paragraph();
    max-width: none !important;
  }
}
