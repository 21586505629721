.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

* {
	box-sizing: border-box;
	word-wrap: normal !important;
}

body {
	line-height: 1.3;
	font-family: $fonts-main;
	background-color: $body-color;
	color: $color-text;
	@include font-size(16px);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @media only screen and (max-width: 600px), screen and (max-height: 400px) {
    &.overlay-open {
      overflow: hidden;

      .site, .site-content {
        ///height: 0px !important;
        // min-height: 0vh;
      }
    }
  }
}

body .site {
  display: block;
  min-height: calc(100vh - 80px);
  flex-direction: column;
  background-color: $color-main;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

body .site-content{
  flex: 1;
  overflow-x: hidden;
  margin: 0 10px 0 10px;
  padding-top: 120px;

  &.has-submenu {
    margin-top: 130px;
  }

  @media only screen and (max-width: 999px) {
    margin-top: 0px;
    padding-top: 70px;

    &.has-submenu {
      margin-top: 70px;
    }
  }
}

// Default styling for non-pagebuilder pages/items .... not? :P
.site-content {
  //padding:180px 0px 50px 0px;
}

.socials {
  display: flex;

  .et_social_inline {
    margin-bottom: 0px;

    .et_social_networks .et_social_icons_container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      li {
        float: none;
        width: auto;
        margin-left: 0.75em;
        @include font-size(12px);
        line-height: 1.4;

        a {
          line-height: inherit;
        }
      }
      .et_social_facebook,
      .et_social_twitter,
      .et_social_linkedin {
        background-color: transparent;
        
        a.et_social_share i.et_social_icon {
          color: $social-purple;
          float: none;
          font-size: inherit;
          line-height: inherit;
          width: auto;
          height: auto;

          &:after {
            display: none !important;
          }

          &:before {
            font-size: inherit;
            line-height: inherit;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
