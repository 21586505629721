/*
Theme Name: Boomerang Kids
Version: 1.0
Description: Theme template for Boomerang Kids
Author: Baytek
Author URI: http://baytek.ca
*/



// Variables
@import "1_variables/colors";
@import "1_variables/general";
@import "1_variables/typography";

// Un-modified vendor files
// @import "vendor/normalize";
@import "2_vendor/reset";

// Base
@import "3_base/mixins";
@import "3_base/general";
@import "3_base/shared";
@import "3_base/typography";
@import "3_base/layouts";
@import "3_base/modules";

// Components
@import "4_components/archives";
@import "4_components/buttons";
@import "4_components/featured-post";
@import '4_components/hero-banner';
@import "4_components/footer";
@import "4_components/forms";
@import '4_components/gravityforms';
@import "4_components/header";
@import "4_components/mobile-menu";
@import "4_components/navigation";
@import '4_components/overlay-popups';

// Divi Components
@import '5_divi/divi';

// Specific pages
@import '6_pages/articles';
@import '6_pages/home';
@import '6_pages/events';
@import '6_pages/pages';
@import '6_pages/locations';
@import '6_pages/404';

// Media styles
@import '7_media/print';
@import '7_media/adminbar';
