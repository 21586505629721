// GENERAL PAGE STYLES

.page-cms {
  .post-footer,
  .info-footer {
    text-align: center;
    margin: 4em 0;

    a h3 {
      @include font-size(22px);
      color: $color-third;
    }
  }
}
