//
// @file     custom-elements/_team-member.scss
// @parent   _divi.scss
//
// @desc     Image Bubble with hover for content to appear
// @usage    About page for team members and partners
//
//
.et_pb_team_member {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  height: 240px;
  min-height: 160px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 30px 2px rgba($black, .1);

  .et_pb_team_member_image {
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-y: hidden;

    img {
      position: absolute;
      top: 0px;
      left: 0px;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .et_pb_team_member_description {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    height: 100%;
    width: 100%;
    padding: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba($purple, .75);
    @include transition();

    h4, .et_pb_module_header,
    p, .et_pb_member_position {
      color: $white !important;
    }
  }

  &:hover .et_pb_team_member_description {
    opacity: 1;
  }
}


.et_pb_section .et_pb_row .et_pb_column .et_pb_team_member {
  margin-bottom: 40px;
  // margin-bottom: 40px !important;
}
