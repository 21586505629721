//
// @file     modules/_toggle.scss
// @parent   _divi.scss
//
// @desc     Customizes the toggle to have proper border and internal color
// @usage
//
//
.et_pb_toggle {
  @include transition();
  border:  1px solid #EAE8EA;
  border-radius: 20px;
  background: transparent;
  box-shadow: 0 0 30px 10px rgba($black, .1);
  padding: 2em;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em !important;


  .et_pb_toggle_title {
    @include font-size(24px);
    line-height: 34px;
    font-family: $fonts-main;
    font-weight: 300;
    color: $color-secondary;
    margin-bottom: 0;
    text-transform: none;

    &:before {
      content: '3';
      font-size: 1.8em;
      color: $color-secondary;
    }

    @media only screen and (max-width: 768px) {
      @include font-size(15px);
      line-height: 18px !important;
    }
  }

  &.et_pb_toggle_open {
    background: $light-grey;

    h5:before {
      content: '2';
    }
  }

  .et_pb_toggle_content p {
    max-width: none;
    margin-bottom: .1em;
    padding-bottom: .8em !important;
  }

  .et_pb_toggle_content ul {
    margin-top: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 1rem;
    box-shadow: 0 0 25px 0px rgba($black, .1);
  }
}
