// Hero image block
body.et_divi_builder #et_builder_outer_content .et_pb_section.hero-banner,
.hero-banner  {
  padding: 0 !important;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: block;
  margin-bottom: 10px;

  background-size: cover;
	background-position: center;
	background-color: $light-grey;
	background-blend-mode: multiply;
	position: relative;

  &:before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/wp-content/themes/boomerangkids/images/placeholders/herobanner.png) no-repeat right center;
    background-size: cover;
    opacity: 1;
    z-index: 2;
  }

  .overlay {
    height: 100%;
    width: 100%;
  }

  .overlay-wrapper {
    color: #FFFFFF;
    z-index: 4;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5em 30px;
    max-width: 1300px !important;
    margin: 0 auto;

    @media only screen and (max-width: 999px) {
      height: auto;
      padding: 3em 30px;
    }
  }
  p.large {
    font-size: 34px !important;
    line-height: 50px !important;

    @media only screen and (max-width: 768px) {
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }
  .overlay-content {
    text-align: center;
  }

  @media only screen and (max-width: 999px) {
    height: auto;
  }

}
