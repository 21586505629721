footer {
	background-color: $footer-color;
	position: relative;
	margin: 0 0 10px 0;
	padding: 1em 2em;

	.credits-wrapper {
		display: flex;
		max-width: none;
		justify-content: space-between;

		p, a {
			@include font-size(12px);
      font-weight: 300;
			color: white;
			text-align: center;
      line-height: 15px;
			text-transform: uppercase;
			letter-spacing: 0.1em;
		}
		a {
			padding: 0 0.6em;
			font-weight: 700;
		}

		.copyright {
			display: flex;
			align-items: center;
		}

		.footer-right {
			display: flex;
			align-items: center;
		}

		.footer-right i {
		  margin-right: 0.5em;
			@include font-size(20px);
		}

		.footer-right a {
			padding: 0em;
		}

		@media only screen and (max-width: 768px) {
			flex-wrap: wrap;
			justify-content: center;

			.copyright {
				flex-wrap: wrap;
			}

			.centered {
				min-width: 100%;
			}

			& > div {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	// Footer mobile
	@media only screen and (max-width: 999px) {

		.wrapper {
			padding: 1.1em 0px;
		}
	}
}
