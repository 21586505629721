//
// @file     custom-elements/_image-text-block.scss
// @parent   _divi.scss
//
// @desc     Aligns text to the center of the section next to an image
// @usage    First-level pages like 'Career Path' and 'Services'
//
//
.et_pb_text .image-left-text {
  display: flex;
  align-items: center;
  padding: 1.5em;
  min-height: 160px;
  border-radius: 20px;
  box-shadow: 0 0 30px 2px rgba($black, .1);

  p {
    max-width: none;
    margin: 0;
  }

  h4 {
    @include large-paragraph();
    color: $grey;

    @media only screen and (max-width: 1300px) {
      font-size: 20px !important;
      line-height: 24px !important;
    }

    @media only screen and (max-width: 980px) {
      @include large-paragraph();
    }
  }

  .text {
    width: calc(100% - 1.5em);

    @media only screen and (max-width: 1300px) {
      width: 70%;
    }
  }

  img {
    width: 30%;
    max-width: 70px;
    max-height: 70px;
    margin-right: 1.5em;

    @media only screen and (max-width: 1300px) {
      margin-right: 5%;
    }

    @media only screen and (max-width: 1100px) {
      width: 20%;
    }

    @media only screen and (max-width: 768px) {
      max-width: 45px;
      max-height: 45px;
    }
  }

  @media only screen and (max-width: 1300px) {
    padding: 1em;
  }

  @media only screen and (max-width: 768px) {
    padding: 1em;
    min-height: 100px;
  }
}

.et_pb_column.icon-column {
  margin-right: 3.5%;

  .et_pb_text {
    margin-bottom: 40px;
  }
}
