#map, #vue
{
	min-height: 800px;
	height: 100%;
	width:100%;

	button {
		border-radius: 0px !important;
		box-shadow: none !important;
		
		&:after {
			display: none;
		}
	}

	.gmnoprint div {
		border: none !important;
	}
}