//
// @file     modules/_toggle.scss
// @parent   _divi.scss
//
// @desc     Customizes the toggle to have proper border and internal color
// @usage
//
//
.et_pb_module blockquote {
  border-left: none;
  margin: 20px 0 30px;
  margin-left: 120px;
  padding-left: 0;

  p {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
  }

  @media only screen and (max-width: 980px) {
    margin: 10px 0 0;
    margin-left: 0;

    p {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
