::selection {
	background-color: $color-secondary;
	color: white;
}

h1, .h1 {  @include h1; }
h2, .h2 {  @include h2; }
h3, .h3 {  @include h3; }
h4, .h4 {  @include h4; }
h5, .h5 {  @include h5; }
p, .p 	{  @include paragraph; }
p.small {	 @include small-paragraph; }
p.large,
#et_builder_outer_content p.large { @include large-paragraph; }


h1 .eyebrow { @include h2-eyebrow; }
h2 .eyebrow { @include h2-eyebrow; }

p {
	&:not(:last-child) {
		margin-bottom: 1em;
	}
}

a {
	text-decoration: none;
	color: $color-link;
	@include transition();
	cursor: pointer;

	#{$hover} {
		color: $color-link-hover;
	}
}

ol  {
	list-style-type: decimal !important;

	li {
		margin-left: 1em;
		padding-left: 0.2em;
	}
}

li.selected a {
	color: $grey !important;
}

strong {
	font-weight: 600;
}

em {
	font-style: italic;
}

sup {
  vertical-align: super;
  @include font-size(11px);
}


.et_divi_builder #et_builder_outer_content .et_pb_section {
	h1, .h1 {  @include h1; }
	h2, .h2 {  @include h2; }
	h3, .h3 {  @include h3; }
	h4, .h4 {  @include h4; }
	h5, .h5 {  @include h5; }
	p, .p 	{
		@include paragraph;
		color: $color-p !important;
	}
	p.small {	 @include small-paragraph; }

	a {
		font-weight: 500;
	}

	strong {
		font-weight: bold;
	}

	b {
		font-weight: bold;
	}

	a.small {
		font-size: 12px;
		font-weight: 300;
		color: #FFF;
		text-transform: uppercase;

		border-bottom: 1px solid #CACACA;
	}


	#{$headings} {
		line-height: 1.1;
		padding-bottom: 0.2em;
	}

	.et_pb_text ul:not(.gform_fields) {

		&:not(:last-child) {
			margin-bottom: 1em;
		}

		li {
			text-indent: -.1em;
			padding-left: 0.2em;

			a {
				color: $color-fourth;

				#{$hover} {
					color: $color-fourth-hover;
				}
			}
		}
	}

	li {
		&:not(:last-child) {
			margin-bottom: 0.5em;
		}
	}

}

.highlight {
	color: $color-main !important;

	&.light {
		color: $color-secondary !important;
	}
}
