.et_divi_builder #et_builder_outer_content {
  .et_pb_section.homepage-banner {
    display: flex;
    padding: 3em 2em 0 !important;
    align-items: center;
    background-color: #F7F7F7;
    border: none;
    overflow: hidden;

    @media only screen and (min-width: 982px) {
      height: calc(100vh - 120px);
      padding: 0 !important;
      overflow-y: hidden;
    }
    @media only screen and (max-width: 500px) {
      background-position-x: calc(50% - 2.5em);
    }
    // @media only screen and (min-height: 1200px) {
    //   height: 900px;
    // }

    h2 {
      margin-bottom: .4em;
    }
    h2:after {
      content: '';
      display: none;
    }

    p.large {
      @include xlarge-paragraph();
      color: $black !important;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: ' ';
      //background: linear-gradient(to bottom, rgba(#00416A,0) 20%,rgba(#00416A,.8) 100%);
    }

    .et_pb_row {
      position: relative;
      z-index: 2;
      padding: 2em 0 0;
      margin-bottom: 0;
      margin-top: 0;
      display: flex;
      align-items: center;

      &#home-banner-icons {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .et_pb_column:first-child {
          align-self: stretch;
          height: 100%;

          .et_pb_code, .et_pb_code .et_pb_code_inner {
            height: 100%;
          }
        }


        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          height: 50px;
          animation-name: whirly-twirly;
          animation-iteration-count: infinite;
        }

        .icon {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 6px;

          &.green { stroke: #8cc649; }
          &.light-green { stroke: #b9d54a; }
          &.blue { stroke: #31add8; }
          &.red { stroke: #e51d32; }
          &.purple { stroke: #75297d; }
          &.orange { stroke: #f2932f; }
          &.yellow { stroke: #face2c; }

          &.bendy,
          &.star,
          &.square,
          &.squiggle {
            stroke-linecap: round;
          }
        }

        // Positioning of the homepage icons
        #bendy-red          { top: 87%; left: 40%; animation-duration: 10s; animation-direction: normal; }
        #circle-green       { top: 65%; left: 40%; animation-duration: 18s; animation-direction: reverse; }
        #circle-light-green { top: 53%; left:  2%; animation-duration: 11s; animation-direction: reverse; }
        #circle-yellow      { top: 25%; left: 43%; animation-duration: 16s; animation-direction: normal; }
        #ellipse-blue       { top: 34%; left: 20%; animation-duration: 20s; animation-direction: normal; }
        #ellipse-purple     { top: 74%; left: 15%; animation-duration: 12s; animation-direction: normal; }
        #square-blue        { top: 90%; left: 58%; animation-duration: 14s; animation-direction: normal; }
        #squiggle-blue      { top: 87%; left:  0%; animation-duration: 18s; animation-direction: normal; }
        #squiggle-green     { top: 10%; left: 77%; animation-duration: 16s; animation-direction: reverse; }
        #squiggle-yellow    { top: 45%; left: 39%; animation-duration: 22s; animation-direction: normal; }
        #star-blue          { top: 13%; left: 91%; animation-duration: 15s; animation-direction: reverse; }
        #star-light-green   { top:  3%; left: 14%; animation-duration: 10s; animation-direction: reverse; }
        #star-orange        { top: 80%; left: 87%; animation-duration: 17s; animation-direction: normal; }
        #triangle-orange    { top: 23%; left: 5%; animation-duration: 19s; animation-direction: normal; }
        #triangle-red       { top:  3%; left: 53%; animation-duration: 13s; animation-direction: normal; }

        @media only screen and (max-width: 980px) {
          // Positioning of the homepage icons
          #bendy-red          { top: 93%; left: 82%; }
          #circle-green       { top: 51%; left: 55%; }
          #circle-light-green { top: 67%; left: 18%; }
          #circle-yellow      { top: 59%; left: 73%; }
          #ellipse-blue       { top: 34%; left: 42%; }
          #ellipse-purple     { top: 90%; left: 15%; }
          #square-blue        { top: 83%; left: 65%; }
          #squiggle-blue      { top: 77%; left:  5%; }
          #squiggle-green     { top: 35%; left: 80%; }
          #squiggle-yellow    { top: 47%; left: 37%; }
          #star-blue          { top: 50%; left: 91%; }
          #star-light-green   { top: 42%; left: 10%; }
          #star-orange        { top: 74%; left: 82%; }
          #triangle-orange    { top: 56%; left:  3%; }
          #triangle-red       { top: 39%; left: 59%; }
        }
        @media only screen and (max-width: 768px) {
          // Positioning of the homepage icons
          #bendy-red          { top: 93%; left: 82%; }
          #circle-green       { top: 46%; left: 52%; }
          #circle-light-green { top: 73%; left: 11%; }
          #circle-yellow      { top: 59%; left: 76%; }
          #ellipse-blue       { top: 28%; left: 32%; }
          #ellipse-purple     { top: 95%; left: 10%; }
          #square-blue        { top: 80%; left: 69%; }
          #squiggle-blue      { top: 85%; left:  5%; }
          #squiggle-green     { top: 25%; left: 80%; }
          #squiggle-yellow    { top: 40%; left: 37%; }
          #star-blue          { top: 47%; left: 91%; }
          #star-light-green   { top: 42%; left:  5%; }
          #star-orange        { top: 74%; left: 86%; }
          #triangle-orange    { top: 57%; left:  3%; }
          #triangle-red       { top: 32%; left: 59%; }
        }

      }

      .et_pb_column:first-child {
        align-self: flex-end;

        .et_pb_image {
          text-align: center;
        }

        img {
          max-height: calc(100vh - 120px - 10em);
        }
      }

      p {
        max-width: 500px;
      }
    }


    .two-buttons {
      display: flex;
      justify-content: center;

      p {
        margin: .5em 1em;
      }
    }
    @media only screen and (max-width: 981px) {
      .et_pb_row {
        display: flex;
        flex-direction: column-reverse;

        &:first-child {
          padding-bottom: 0;
        }

        .et_pb_column:first-child {
          margin-top: 20px;
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    @media only screen and (min-width: 982px) {
      .et_pb_column {
        z-index: 2;

        &:first-child {
          margin-left: 2.75%;
          margin-right: 2.75%;
        }
      }
    }
  }
  .et_pb_section.homepage-section {
    height: calc(100vh - 150px);
    min-height: 900px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
      min-height: auto;
      height: auto;
      padding: 20px 0 !important;
    }

    &.no-padding {
      padding: 0px !important;
      align-items: stretch;
      overflow: hidden;

      .et_pb_row.full-size {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;

        &.right {
          margin-right: 0;
          padding-right: 0;

          .et_pb_column_1_2.et-last-child {
            height: 100%;
            width: calc(50% + 35px + 1.1em);
          }
        }

        @media only screen and (max-width: 1000px) {
          padding: 20px 25px !important;

          &:first-child {
            padding-top: 40px !important;
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      .et_pb_row {
        display: flex;
        align-items: center;
      }
    }

    @media only screen and (min-width: 1440px) {
      .et_pb_text,
      .et_pb_button {
        max-width: 500px;
        margin-left: 60px;
      }
    }
  }

}

