//
// @file     custom-elements/_narrow-section.scss
// @parent   _divi.scss
//
// @desc     Creates a section with narrower rows
// @usage    On Product Pages
//
//

// **
// **  Narrow Section found on product pages
// **
.et_pb_section.narrow-section {

  .et_pb_row {
    max-width: 900px;
    display: flex;
  }
  .et_pb_row:first-child .et_pb_column {
    justify-content: center;
  }

  .et_pb_column {
    display: flex;
    align-items: center;
  }

  .et_pb_row.top.aligned {
    align-items: flex-start;
  }

  .et_pb_column.nomaxwidth .et_pb_text {
    margin-left: 0;
    margin-bottom: 1.5em;
  }

  @media only screen and (max-width: 999px) and (min-width: 769px) {
    .et_pb_column,
    .et_pb_column.nomaxwidth {
      margin: 0 .8em;
    }

    .mobile-reverse.et_pb_row {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 768px) {
    .et_pb_row {
      flex-wrap:wrap;
    }

    .et_pb_row:last-child {
      justify-content: center;
    }
  }
}
