@keyframes slideIn {
	from { transform: translateY(100%); opacity: 0; }
	to { transform: translateY(0%); opacity: 1; }
}
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
@keyframes modalIn {
  0%   { transform: translateY(5%); opacity: 0; }
  50%  { transform: translateY(-1%); opacity: 0.50;}
  100% { transform: translateY(0%); opacity: 1; }
}
@keyframes whirly-twirly {
  0%   { transform: rotate(0deg)   translateX(15px) rotate(0deg);    animation-timing-function: linear; }
  100% { transform: rotate(360deg) translateX(15px) rotate(-360deg); animation-timing-function: linear; }
}
// Dumb/hacky hidey showey animation to help with lang switcher border overlap issue
@keyframes touchMeStupid {
  0%   { border-bottom-width: 2px; }
  1%   { border-bottom-width: 1px; }
  100% { border-bottom-width: 1px; }
}

#et_builder_outer_content .et_pb_section .et_pb_row .et_pb_module {
    margin-bottom: 4%;

    &:first-child {
      margin-bottom: 1%;
    }

    &.et_pb_text {
      margin-bottom: 5.82%;
    }

    p {
      color: $color-p;
    }

    .tt, code {
      font-family: 'Monaco', 'Monaco', monospace;
      font-size: smaller;
      background: rgba(0, 0, 0, 0.1);
      padding: 0.125em 0.25em;
      white-space: pre;
    }

    pre {
      margin: 0px 1em 1em 1em;
      font-family: 'Monaco', 'Monaco', monospace;
      white-space: pre;
      font-size: smaller;
    }

    p2 {
      @include font-size(14px);
    }

    &.medium-width p,
    &.medium-width h1 {
      max-width: 800px !important;
      margin: 0 auto;
    }

    a {
      color: $color-third;
      font-weight: 600;
      @include transition();

      #{$hover} {
        color: $color-third-hover;
      }

      &.inverse {
        color: $color-main;

          #{$hover} {
          color: $color-main-hover;
        }
      }
    }

    ol.roman > li {
      list-style-type: lower-roman;
    }

    ul:not(.gform_fields) > li,
    ol > li {
			@include font-size(14px);
      margin-bottom: 0em;
      color: $color-p;
			font-weight: 300;
      text-indent: 0em;
      margin-left: 1em;

      &.large {
        @include font-size(19px);
        color: $color-third;
        font-weight: 600;
      }
    }

		a.etmodules-link {
      display: flex;
      align-items: center;
			justify-content: flex-start;
      color: $color-link;
			font-weight: 700;
			letter-spacing: 0.05em;
			@include font-size(11px);
			text-transform: uppercase;
      @include transition();

      .etmodules {
        @include transition();
        @include font-size(14px);
        padding-bottom: 0.2em;
      }

      #{$hover} {
        color: $color-link-hover;

        .etmodules {
          color: $color-link-hover;
        }
      }
    }
}

body.et_divi_builder #et_builder_outer_content .et_pb_fullwidth_section {
  z-index: 1 !important;
}


.popout-image img:after {
  color: black;
  opacity: 0;
  @include transition(0.2s);
  width: 100%;
  height: 100%;
}
.popout-image img:hover:after {
  opacity: 0.2;
}

pre {
  margin: 0px 1em 1em 1em;
  font-family: 'Monaco', 'Monaco', monospace;
  white-space: pre;
  font-size: smaller;
}

.etmodules {
  font-family: 'ETModules' !important;
  display: inline-block;
  vertical-align: bottom;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
