//
// @file    general.scss
// @theme   boomerangkids
//
// @desc    defines theme general settings
// @usage
//
//

$wrapper-width: 1600px;
$wrapper-width-narrow: 770px;
$content-max-width: 1400px;
$narrow-text: 700px;
$border: 1px solid $color-border;
