// Overlay
@mixin overlay($bgc: null) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bgc;
}

// Positioning
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

$tablet-width: 768px;
$desktop-width: 1024px;


// Tablet Media Query Mixin
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

// Tablet Media Query Mixin
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

// Positioning
@mixin stupidSafariFlex($flex-grow, $flex-shrink, $min-width) {
  flex: $flex-grow $flex-shrink $min-width;
    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        flex: $flex-grow;
    }
}

@mixin centerposition() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@mixin center($maxwidth: null) {
  margin-left: auto;
  margin-right: auto;
  max-width: $maxwidth;
}

// Transition
@mixin transition($duration: 0.2s) {
  transition: all $duration ease-out;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

// Button styling
@mixin button($bg-color: $color-main, $bg-hover: $color-third, $text-color:#000, $text-hover:#333, $radius: 10px) {
  background-color: #{$bg-color};
  color: #{$text-color} !important;
  border-radius: #{$radius};
  padding: .9em 1.8em;
  line-height: 1em;
  margin: 0.5em 0;
  font-weight: 700;
  display: inline-block;
  @include font-size(11px);

  &:after {
      font-family: 'FontAwesome' !important;
      content: '\f105';
      padding-left: 0.5em;
      position: relative;
      font-size: 110%;
  }

  #{$hover} {
    background-color: #{$bg-hover};
    border: none;
    color: #{$text-hover} !important;
    padding: .9em 1.8em;
  }

}


// Intended to be used in combination of the button mixin for reversing the icon/direction for the buton
@mixin reverse-button-icon() {
  &:before {
    font-family: 'FontAwesome' !important;
    content: '\f104';
    padding-right: 0.5em;
    position: relative;
    font-size: 110%;
  }
  &:after {
    content: '';
    padding-left: 0;
  }
}

@mixin no-button-icon() {
  &:before {
    content: '';
    padding-right: 0;
  }
  &:after {
    content: '';
    padding-left: 0;
  }
}


// Output rem with a px fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/*
 * HEADINGS
 */
@mixin h1() {
  @include font-size(80px);
  line-height: 90px;
  font-weight: 700;
  margin-bottom: 8px;
  color: $color-head;

  @media only screen and (max-width: 768px) {
    @include font-size(30px);
    line-height: 34px;
  }
}

@mixin h2() {
  @include font-size(64px);
  line-height: 72px;
  font-weight: 300;
  color: $color-head;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: .2em;

  @media only screen and (max-width: 980px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    @include font-size(26px);
    line-height: 30px;
  }
}

@mixin h2-eyebrow() {
  font-size: 20px !important;
  line-height: 17px;
	font-weight: 400 !important;
	margin-bottom: 10px !important;
  letter-spacing: 0.05em !important;
	color: $color-secondary !important;
  text-transform: uppercase;
	display: block;
}

@mixin h3() {
  @include font-size(36px);
  font-weight: 400;
  margin-bottom: 0.6em;
  line-height: 42px;
  color: $color-secondary;

  @media only screen and (max-width: 768px) {
    @include font-size(20px);
    line-height: 24px;
  }

}
@mixin h4() {
  @include font-size(26px);
  font-weight: 700;
  line-height: 31px;
  color: $color-secondary;

  @media only screen and (max-width: 768px) {
    @include font-size(18px);
    line-height: 24px;
  }
}

@mixin h5() {
  @include font-size(18px);
  font-weight: 400;
  margin-bottom: 5px;
  color: $color-head;
}

@mixin paragraph() {
  @include font-size(16px);
  font-weight: 300;
  color: $color-p;
  line-height: 22px;

  @media only screen and  (max-width: 768px) {
    @include font-size(13px);
    line-height: 17px;
  }
}
@mixin small-paragraph() {
  @include font-size(16px);
  font-weight: 300;
  color: $color-p;
  line-height: 20px;
}
@mixin large-paragraph() {
  font-size: 24px !important;
  font-weight: 300 !important;
  margin-bottom: 8px !important;
  line-height: 34px !important;
  max-width: 640px !important;
  margin: 0 auto;
  padding-bottom: 0em !important;

  @media only screen and (max-width: 980px) {
    max-width: 100% !important;
  }

  @media only screen and  (max-width: 768px) {
    font-size: 15px !important;
    line-height: 20px !important;
  }
}
@mixin xlarge-paragraph() {
  font-size: 34px !important;
  line-height: 50px !important;

  @media only screen and (max-width: 980px) {
    max-width: 100% !important;
  }

  @media only screen and  (max-width: 768px) {
    font-size: 24px !important;
    line-height: 34px !important;
  }
}

.no-padding {
  padding: 0 !important;
}

@for $i from 1 to 10 {
  header .header-center-menu li:nth-child(#{$i}) { animation-delay: $i * 0.1s; }
}
