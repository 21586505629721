.wrapper,
.et_divi_builder #et_builder_outer_content .et_pb_section .et_pb_row {
	width: 100%;
	@include center($wrapper-width);
	max-width: $content-max-width;
	padding: 1.1em 35px;

	@media only screen and  (max-width: 768px) {
    padding: 0.8em 25px;
  }
}

body.et_divi_builder #et_builder_outer_content {
	line-height: 1.5;

	.et_pb_section {
		padding: 5em 0 !important;
		border: 1px solid #EAE8EA;
		margin-bottom: 10px;

		&.et_pb_fullwidth_section {
			padding: 0 !important;
		}

		&.et_pb_with_background.bg-image-half {
			color: black;
			background-size: 50% !important;

			.et_pb_row {
				display: flex;
			}
		}

		& .et_pb_row {
			text-align: center;
		}

		@media only screen and (max-width: 1300px) {
			&.et_pb_with_background.bg-image-left {
				background-size: 75% !important;
				background-position-x: -75%;
			}

			&.et_pb_with_background.bg-image-right {
				background-size: 75% !important;
				background-position-x: 175%;
			}
		}

		@media only screen and (max-width: 980px) {
			.et_pb_row {
				padding: 20px 25px;
				text-align: center;

				.et_pb_text {
					text-align: center;
				}
			}

			&.et_pb_with_background.bg-image-half {
				background-image: none !important;
				padding-bottom: 0px !important;

				.et_pb_row {
					flex-direction: column-reverse;
					padding-bottom: 0px;

					.et_pb_column:first-child {
						margin-bottom: 0px;
					}
				}
			}

			&.et_pb_with_background.bg-image-right {
				.et_pb_row {
					flex-direction: column;
				}
			}
		}

		@media only screen and (max-width: 600px) {
			padding: 2.1em 0 !important;
		}
	}

	.et_pb_column {
		z-index: auto;
	}

	.et_pb_column_empty {
		height: 100%;
	}

	.et_pb_column_4_4 {
		ul,
		ol,
		p {
			@include center($narrow-text);
		}

		ul, ol {
			max-width: none !important;
		}
	}
}
