//
// @file    colors.scss
// @theme   boomerangkids
//
// @desc    defines theme colors
// @usage
//
//

// Palette colors
$white: #FFFFFF;
$black: #1D0A1F;
$real-black: #000000;

$blue: #2682C5;
$blue-hover: darken( $blue, 20% );

$green: #8CC74A;
$green-hover: darken( $green, 20% );

$purple: #74297C;
$social-purple: #A298A3;

$yellow: #FACE2C;
$yellow-hover: darken( $yellow, 20% );

$grey: #474648;
$light-grey: #F7F7F7;
$grey-blue: #99B5C9;
$very-light-grey: #EAE8EA;

/*
  Site Colors
*/
$color-main: $white;
$color-secondary: $purple;
$color-main-hover: $color-secondary;

$color-third: $green;
$color-third-hover: $green;

$color-fourth: $yellow;
$color-fourth-hover: $yellow-hover;

// 1. Document colors
$body-color:  #e7e9ea;

// 2. Header
$header-color: $white;
$header-link-color: $grey;
$header-link-hover: $color-secondary;
$header-dropdown-spacer: $very-light-grey;

// 3. Block element colors
$color-bg: $color-secondary;
$color-border: #cacaca;

$card-hover: $very-light-grey;

// 4. Text element colors
$color-link: $green;
$color-link-hover: $green-hover;

$color-text: $grey;
$color-p: #474648;
$color-head: $black;

$button-color: $purple;
$button-hover-color: $color-fourth;

$input-placeholder: #ACA2AD;
$input-grey: darken( $input-placeholder, 20% );

$error: #b92b27;


// 5. Footer
$footer-color: $color-third;
$footer-text: #cbcad0;
$footer-link: $color-main;
