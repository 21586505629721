body .fullscreen-overlay {
  position: fixed;
  display: none;
  z-index: 9999999;
  transition: all 0.1 ease-out;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: inherit;
  overflow-y: auto;
  background-color: rgba($white, .9);

  &.active {
    display: flex !important;
  }

  .fullscreen-content {
    margin: auto;
    text-align: center;
    overflow-y: visible;
    overflow-x: hidden;
    position: relative;
    max-width: 1000px;
    //min-height: 646px;
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0 0 30px 10px rgba($black, .1);
    display: flex;

    @media only screen and (max-width: 999px) {
      margin-left: 30px;
      margin-right: 30px;
    }

    #nav-logo {
			width: 225px;
			padding: 5px 5px;
			margin-top: 20px;
      margin-bottom: 20px;
			transition: all 0.4s ease-out;

			@media only screen and (max-width: 999px) {
				margin-top: 8px;
			}
      @media only screen and (max-width: 768px) {
				width: 150px;
			}
		}

    .header, .main-area {
      margin: 0 auto;
      max-width: 650px;
    }

    .scroller {
      max-width:1000px;
      margin: 0 auto;
    }

    h1 {
      margin-bottom: .4em;
    }

    a {
      color: $color-third;
    }

    label {
      margin-bottom: 0.1em;
    }

    .panel.slide-left {
      transform: translateX(0%);
    }
    .panel.slide-right {
      transform: translateX(-200%);
    }

    .panel {
      padding: 45px 65px 55px 65px;
      flex: 0 0 100%;
      display: flex;
      flex-direction: column;
      //transform: translateX(-100%);
      //transition: transform 0.5s ease-out;

      .header {
        margin-bottom: 2em;
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 100%;

        .item {
          flex: 1;

          &:first-child {
            text-align: left;
          }
          &:last-child {
            text-align: right;
          }
        }
        h2.item {
          flex-basis: 75%;

          @media only screen and (max-width: 1000px) {
            flex-basis: 50%;
          }
        }

        .back-to-signin {
          text-transform: uppercase;
          font-size: 12px;
          color: $input-placeholder;

          #{$hover} {
            color: $input-grey;
          }

          i {
            margin-right: 0.5em;
          }
        }
      }

      .middle {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        form:not(:first-child) {
          margin-top: 4em;
        }

        form {
          input[type="text"],
          input[type="password"],
          input[type="email"] {
            height: 100px;
            border-radius: 20px;
            box-shadow: 0 0 30px 0px rgba($black, .1);
            border: none;
            margin-bottom: 1em;
            font-size: 24px;
            color: $input-grey;

            @media only screen and (max-width: 768px) {
              height: 80px;
              font-size: 16px;
            }
          }

          .errors {
            font-size: 24px;
            margin-bottom: 1em;
            color: $error;

            @media only screen and (max-width: 768px) {
              font-size: 16px;
            }
          }

          input.error {
            border: solid 1px $error;
          }

          button {
            box-shadow: 0 0 40px 0px rgba($black, .5);
            margin-top: 2em;
          }

          ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $input-placeholder;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: $input-placeholder;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: $input-placeholder;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: $input-placeholder;
          }

          .form-meta {
            margin-top: 2em;

            .spacer {
              margin: 0 1em;
            }
          }
        }

        .success {
          display: none;
        }
      }
    }

    div.constant-contact {
      padding: 45px 65px 55px 65px;

      .ctct-inline-form {
        .ctct-form-container {
          .ctct-form-defaults {
            padding: 0px;

            form.ctct-form-custom,
            div.ctct-form-success {
              h2.ctct-form-header {
                @include h2();
                margin-bottom: 1.5em;
                font-family: $fonts-head;
              }

              p.ctct-form-text {
                @include large-paragraph();
                font-family: $fonts-head;
              }

              label.ctct-form-label {
                display: none;
              }

              button.ctct-form-button {
                // @MIXIN:
                // button(buttonColor, buttonHoverColor, textColor, textHoverColor, borderRadius)
                @include button($button-color, $button-hover-color, $color-fourth, $color-secondary, 20px);
                @include font-size(16px);
                @include transition();
                line-height: 1.7em !important;
                text-transform: uppercase;
                letter-spacing: 0.1em !important;
                border: 1px solid $button-color;
                cursor: pointer;
                font-family: $fonts-head;
                -webkit-appearance: none;
                box-shadow: 0 0 40px 0px rgba($black, .5);
                margin-top: 2em;
                width: inherit;

                #{$hover} {
                  border: 1px solid $color-fourth;
                }

                &.prev {
                  @include reverse-button-icon();
                }

                &.all, &.bis-load-more {
                  @include no-button-icon();
                }
                &.bis-load-more .etmodules {
                  padding-left: 0.5em;
                }

                // &:after {
                //   content: none !important;
                // }

                &:active {
                  #{$hover} {
                  }
                }
              }

              input[type="text"],
              input[type="password"],
              input[type="email"] {
                height: 100px;
                border-radius: 20px;
                box-shadow: 0 0 30px 0px rgba($black, .1);
                border: none;
                margin-bottom: 1em;
                margin-top: 0.25em;
                font-size: 24px;
                color: $input-grey;

                @media only screen and (max-width: 768px) {
                  height: 80px;
                  font-size: 16px;
                }
              }
            }

            p.ctct-form-footer a {
              text-decoration: none;
              color: $color-third;
              font-family: $fonts-head;
              font-size: 16px;
              font-weight: 400;
              margin-top: 2em;

              &:hover {
                color: $color-third-hover;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      margin-top: 95px;
      max-width: none;
      min-width: 100px;
      padding: 40px 25px 20px;
      overflow-y: auto !important;

      .panel {
        padding: 35px 35px 45px; 
      }
    }

    // When desktop browser size is very squished
    @media only screen and (min-width: 768px) and (max-height: 780px) {
      margin-top: 20px;
      overflow-y: auto;
    }
  }

  // Form styling
  .gform_wrapper form {
    border: none;

    div.validation_error {
      color: #ff5e5e;
      margin-bottom: 20px;
      border-top: none;
      border-bottom: none;
    }

    .gform_body {

      .gform_fields {
        display: flex;
        flex-wrap: wrap;

        .gfield {
          width: 100%;
        }
      }

      .ginput_container {
        padding-bottom: .8em;

        &.ginput_container_textarea {
          padding-bottom: 0em;
        }
        @media only screen and  (max-width: 768px) {
          padding-bottom: 0.6em;
        }
      }

      .ginput_recaptcha {
        padding-bottom: 0.4em;
      }

      label {
        font-weight: 600;
        margin-bottom: .5em;
      }

      ul li:before {
        display: none !important;
      }

      input[type="text"],
      input[type="password"],
      textarea {
        width: 100%;
        border-color: rgba($color-third, 0.8);
        border: 1px solid rgba($color-third, 0.8);
        background: $white;
        font-size: 16px;
        color: $color-fourth;
        padding: .7em .7em .5em !important;
      }
      input[type="text"],
      input[type="password"]{
        height: 50px;
      }
      select {
        width: 100%;
        border: 1px solid rgba($color-third, 0.8);
        color: $color-fourth;
        padding: 0em 0.7em !important;
        font-size: 18px;
        margin-top: 0px;
        height: 50px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        background: url(/wp-content/themes/boomerangkids/images/logos/dropdown-arrow.svg) no-repeat right center;
        background-size: 48px;
        background-color: $white;
      }

    }

    .gform_footer {
      text-align: center;

      input[type="submit"] {
        // @MIXIN:
      	// button(buttonColor, buttonHoverColor, textColor, textHoverColor, borderRadius)
      	@include button($button-color, $button-hover-color, $color-third, $color-main, 50px);
      	@include font-size(11px);
      	@include transition();

      	text-transform: uppercase;

      	letter-spacing: 0.1em !important;

      	border: 1px solid $color-third;
      	box-shadow: 0 5px 30px 1px rgba(0,0,0,0.1);

      	cursor: pointer;
      	font-family: $fonts-head;
      	-webkit-appearance: none;

      	#{$hover} {
      		border: 1px solid $color-third;
      	}

      	&:after {
          content: none !important;
        }

      	&:active {
      		#{$hover} {
      		}
      	}
      }

      @media only screen and (max-width: 768px) {
        input[type="submit"] { max-width: 200px }
      }
    }
  }


  .gforms_confirmation_message {
    padding: 3em 0em 1em;
    @include font-size(20px);
    color: white;
  }

  // Close button
	.close-overlay {
		$size: 50px;
		height: $size;
		width: $size;
		box-sizing: border-box;
		line-height: $size;
		position: absolute;
		cursor: pointer;
		top: 25px;
		right: 25px;
    z-index: 9999;
    color: $color-secondary;

		&:before, &:after {
				$width: 45px;
				$height: 3px;
				transform: rotate(-45deg);
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -$height/2;
				margin-left: -$width/2;
				display: block;
				height: $height;
				width: $width;
				background-color: $color-secondary;
        border-radius: 5px;
				transition: all 0.25s ease-out;
		}
		&:after {
				transform: rotate(-135deg);
		}
		&:hover {
				&:before, &:after {
						transform: rotate(0deg);
						background-color: $color-third;
				}
		}

		@media only screen and (max-width: 768px) {
			height: 50px;
			position: absolute;
      top: 25px;
  		right: 25px;
			&:before, &:after {
          $width: 35px;
          $height: 3px;
					margin-top: -$height/2;
					margin-left: -$width/2;
					height: $height;
					width: $width;
			}
		}
		@media only screen and (max-width: 400px) {
			height: 50px;
			position: absolute;
			top: 15px;
			right: 15px;
			&:before, &:after {
          $width: 35px;
          $height: 3px;
					margin-top: -$height/2;
					margin-left: -$width/2;
					height: $height;
					width: $width;
			}
		}
	} // End close button
}
