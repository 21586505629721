//
// @file     custom-elements/_aligned-half-section.scss
// @parent   _divi.scss
//
// @desc     Aligns text to the center of the section next to an image
// @usage    First-leve pages like 'Career Path' and 'Services'
//
//
.et_pb_section.aligned-half {

  &.full-background {
    padding: 0 !important;

    .et_pb_row {
      padding: 0;
      max-width: none;
    }

    .et_pb_column {
      width: 50%;
      margin-right: 0;
      padding: 2em;
    }

    .et_pb_column_empty {
      height: 650px;
    }
  }
  &.full-background.short {
    .et_pb_column_empty {
      height: 480px;

      @media only screen and (max-width: 999px) {
        height: 250px;
        margin-bottom: 0 !important;
      }
    }
  }
  .et_pb_row {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 999px) and (min-width: 769px) {
    .et_pb_column,
    .et_pb_column.nomaxwidth {
      margin: 0 .8em;
    }

    .mobile-reverse.et_pb_row {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 768px) {
    .et_pb_row {
      flex-wrap: wrap;
    }

    &.full-background .et_pb_column_empty {
      height: 450px;
      width: 100%;
      display: block;
    }

    p.large {
      margin: 0 auto;
    }
  }
}
