nav {
	li {
		display: inline-block;
		list-style: none;
	}
}

body.et_divi_builder #et_builder_outer_content {

	.et_pb_section.floating-submenu {
		background-color: transparent !important;
		top: 90px;
		position: absolute;
		height: 100px;
		width: 100%;
		padding: 20px !important;
		border-top: 1px solid rgba(white, 0.3);

		.et_pb_row {
			padding: 0% !important;
		}
		ul {
			display: flex;
			width: 100%;
			justify-content: center;
		}
		ul li {
			list-style: none;
			padding: 0px 1.4em;

			a {
				font-size: 15px;
				color: #FFF;
				font-weight: 600;

				#{$hover} {
					color: $header-link-hover !important;
				}
			}
		}

		@media only screen and (max-width: 999px) {
			display: none;
		}
	}
}


.social-menu .icon {
	a {
		font-size: 0;
		display: block;
		line-height: 1;

		&:before {
			font-family: fontawesome;
			@include font-size(14px);
			display: block;
			color: inherit;
		}

		&[href*="facebook"] {
			&:before {
				content: "\f09a";
			}
		}

		&[href*="twitter"] {
			&:before {
				content: "\f099";
			}
		}

		&[href*="pinterest"] {
			&:before {
				content: "\f231";
			}
		}

		&[href*="google"] {
			&:before {
				content: "\f0d5";
			}
		}

		&[href*="instagram"] {
			&:before {
				content: "\f16d";
			}
		}

		&[href*="youtube"] {
			&:before {
				content: "\f16a";
			}
		}

		&[href*="linkedin"] {
			&:before {
				content: "\f0e1";
			}
		}

	}
}

.posts-nav {
	display: flex;
	width: 100%;
	margin-top: 3em;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;

	.nav-wrap {
		flex: 1 1 33.33%;
		text-align: center;

		&:first-child {
			text-align: left;
		}
		&:last-child {
			text-align: right;
		}
	}
}
