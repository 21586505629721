//
// @file     custom-elements/_pre-footer.scss
// @parent   _divi.scss
//
// @desc     Styles the common pre-footer
// @usage
//
//
.et_pb_section.pre-footer {
  padding: 10em 0!important;
  background-color: $color-third;
  margin-bottom: 0;
  border: none;
  position: relative;

  &:before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/wp-content/themes/boomerangkids/images/placeholders/footer-bg.png) no-repeat right center;
    background-size: cover;
    opacity: .18;
  }

  h2, h2 .eyebrow, p.large, p, h3, h4, .et_pb_text_inner {
    color: $white !important;
  }

  .et_pb_row .et_pb_column_1_3 {
    width: 33.33%;
    margin-right: 0;
    padding: 1.5em 3em;

    @media only screen and (max-width: 1024px) {
      padding: 1.5em;
    }
    @media only screen and (max-width: 768px) {
      padding: 0;
      // max-width: 640px;
    }
  }

  .et_pb_row.three-row {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .et_pb_column_1_3 {
        margin-bottom: 40px;

        &:nth-child(2) {
          // border-top: 2px solid rgba($white, .5);
          // border-bottom: 2px solid rgba($white, .5);
          border-left: none;
          border-right: none;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .et_pb_row .et_pb_column_1_3:nth-child(2) {
    border-left: 2px solid rgba($white, .5);
    border-right: 2px solid rgba($white, .5);
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: ' ';
  }

  h4 {
    margin-bottom: .7em;
  }
  p {
    margin-bottom: 1em;
  }


  button {
    margin-top: 1.5em;
    box-shadow: 0 10px 40px 3px rgba(0,0,0,0.2);
  }

  @media only screen and (max-width: 768px) {
    padding: 2em 0!important;
  }
}
