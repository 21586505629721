body .article-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 3em;
  border: 1px solid $light-grey;

  h2 {
    max-width: none;

    &:after {
      content: '';
    }
  }

  // News Post Styling
  .post-cms {
    box-shadow: 0 0 30px 0px rgba($real-black, .1);
    width: 100%;
    border-radius: 20px;
    padding: 1.5em;
    display: flex;
    flex-wrap: wrap;
  }
  .post-cms .post-meta {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .8em;

    .meta-item {
      margin-right: 2em;
    }

    p {
      @include font-size(12px);
      line-height: 1.4;
      text-transform: uppercase;
    }
  }
  .post-cms .date-cat {
    color: $color-third;
    margin-bottom: .8em;
  }
  .post-cms .post-image {
    min-height: 360px;
    max-height: 360px;
    min-width: 360px;
    max-width: 360px;
    background-position: center;
    background-size: cover;
    margin-right: 1.5em;
    border-radius: 50%;
    flex: 1;
  }
  .post-cms .post-content {
    flex: 2;

    p {
      @include large-paragraph();
      max-width: none !important;
    }
  }

  .post-navigation {
    padding: 3em 0;
    margin-top: 3em;
  }
  .post-navigation a {
    font-weight: 600;
    display: flex;
    align-items: center;

    img {
      max-width: 30px;
    }
  }
  .post-navigation .nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .post-navigation .nav-previous img {
    margin-right: 1em;
    transform: scaleX(-1);
  }
  .post-navigation .nav-next img {
    margin-left: 1em;
    margin-right: 2em;
  }

  @media only screen and (max-width: 999px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    .post-sidebar {
      margin-right: 0;
      margin-top: 2em;
    }

    .post-sidebar,
    .post-cms {
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
    }
  }

  article.event {
    ul {
      @include font-size(24px);

      list-style: circle;
      margin-bottom: 1rem;
      font-weight: 300;
      color: $color-secondary;

      li {
        margin-left: 2.5rem;
        color: $color-p;
      }
    }
  }
}
