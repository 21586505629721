
.archive-cms {
  width: 100%;
}

// General Archive Wrapper
.archive-wrapper {
  padding: 4em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media only screen and (max-width: 999px) {
    flex-wrap: wrap;
    padding: 35px;
    flex-direction: column-reverse;

    .archive-sidebar {
      min-width: 100%;
    }
  }
}

// Event Archive Styling
.archive-container.events {

  .archive-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .archive-item {
    margin-right: 3em;
    margin-bottom: 3em;
    display: block;
    position: relative;
    width: calc(33.33% - 6em/3);

    &:hover {
      background-color: $card-hover;
    }
  }
  .archive-item:nth-child(3n + 3) {
    margin-right: 0;
  }
  .archive-item > a {
    min-width: 100%;
    height: inherit;
  }
  // .archive-item .thumb-image {
  //   min-width: 100%;
  //   height: 280px;
  //   background-size: cover;
  //   margin-bottom: 1.5em;
  // }
  .archive-item .featured-meta {
    @include font-size(14px);
    color: $color-third;
    margin-bottom: .8em;
  }
  .archive-item .featured-excerpt {
    margin-bottom: .8em;
  }
  .archive-item .event-links a {
    @include font-size(14px);
    font-weight: 600;
  }

  .archive-item h4 {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .archive-item {
      width: 100%;
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
}

// Location Archive Styling
body.et_divi_builder #et_builder_outer_content #places-archive-container.et_pb_section {
  padding-bottom: 0 !important;
  padding-top: 2rem !important;

  .results {
    display: flex;
    flex-wrap: wrap;
  }

  .archive-item.place {
    margin-right: 40px;
    display: block;
    position: relative;
    max-width: calc(25% - 120px/4);
    margin-bottom: 40px;
    box-shadow: 0 0 30px 0px rgba($real-black, .1);

    &:hover {
      background-color: inherit;
    }
  }
  .archive-item.place:nth-child(4n + 4) {
    margin-right: 0;
  }

  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    .archive-item.place {
      min-width: 40%;
      max-width: calc(50% - 40px/2);

      &:nth-child(4n + 4) {
        margin-right: auto;
      }
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
  }

  .archive-item.place > a {
    min-width: 100%;
    height: inherit;
  }
  .archive-item.place .thumb-image {
    max-width: 194px;
    min-width: 194px;
    width: 194px;
    height: 194px;
    background-size: cover;
    margin-bottom: 1.5em;
  }

  .archive-item.place .archive-meta {
    p.directions {
      margin-left: 0;
      margin-right: auto;

      a {
        @include font-size(12px);
        text-transform: uppercase;
        color: $color-p;
        font-weight: 400 !important;
        letter-spacing: 1px;

        i {
          @include font-size(14px);
        }
      }
    }

    .socials a {
      @include font-size(16px);
      color: $social-purple;
    }
  }

  .archive-item.place h4 {
    margin-bottom: 1rem;
  }

  .archive-item.place .archive-content {
    margin: 0 auto;
  }

  .archive-item.place .featured-meta {
    @include font-size(14px);
    color: $color-third;
    margin-bottom: .8em;
  }
  .archive-item.place .featured-excerpt {
    margin-bottom: .8em;
  }
  .archive-item.place .event-links a {
    @include font-size(14px);
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    .archive-item.place {
      width: 100%;
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
}


// Articles/Blog Archive Styling
.archive-container,
body.et_divi_builder #et_builder_outer_content .et_pb_section {

  .archive-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .archive-item {
    display: block;
    position: relative;
    flex: 1 1 30%;
    max-width: calc(33.333% - 80px/3);
    padding: 1.5em;
    margin-right: 40px;
    margin-bottom: 40px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0px rgba($real-black, .1);
    text-align: center;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      background-color: $card-hover;
    }

    &:not(.place) {
      cursor: pointer;
    }
  }
  .archive-item .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .archive-item .row > a {
    height: inherit;
    max-width: 300px;
  }
  .archive-item .thumb-image {
    min-width: 250px;
    height: 250px;
    margin: 0 auto 1em;
    border-radius: 50%;
    background-size: cover;
    box-shadow: inset 0 0 5px 0px rgba($real-black, .1);

    @media only screen and (max-width: 1120px) {
      min-width: 200px;
      height: 200px;
    }

    @media only screen and (max-width: 900px) {
      min-width: 165px;
      height: 165px;
    }

    @media only screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
    }
  }
  .archive-item .archive-meta {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .8em;

    p {
      @include font-size(12px);
      line-height: 1.4;
      text-transform: uppercase;
    }
  }
  .archive-item .featured-excerpt {
    margin-bottom: .8em;
  }

  // @media only screen and (max-width: 768px) {
  //   .archive-item {
  //     border-bottom: 1px solid $color-third;
  //     padding-bottom: 1em;
  //     margin-bottom: 2em;
  //   }
  //   .archive-item .row {
  //     flex-wrap: wrap;
  //   }
  //   .archive-item .row > a,
  //   .archive-item .row .archive-content {
  //     padding-bottom: 1em;
  //     margin-bottom: 0;
  //     border-bottom: none;
  //     flex: 1;
  //     min-width: 100%;
  //     @include stupidSafariFlex(1,1,100%);
  //   }
  //   .archive-item .thumb-image {
  //     //min-width: 100%;
  //   }
  // }
  @media only screen and (max-width: 768px) {
    .archive-item {
      width: 100%;
      min-width: 100%;
      @include stupidSafariFlex(1,1,100%);
      margin-right: 0;
      margin-bottom: 2em;
    }
  }
}

.bis-load-more-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
