.menu-toggle, #toggle-right {
	display: none;
}


// Tablets
@media only screen and (max-width: 1000px) {

	header nav.main-navigation > .header-left-menu,
	header nav.main-navigation > .header-right-menu .social-icon,
	header nav.main-navigation > .header-right-menu .menu-social-menu-french-container,
	header nav.main-navigation > .header-right-menu .menu-social-menu-container {
		display: none;
	}

	nav {
		position: relative;

		.menu-toggle {
			display: block;
		}
		.menu-toggle  {
			width: initial;
			height: inherit;
			margin-right: -15px;

			// Credit for animated SVG menu to Tamino Martinius
			svg {
				width: 60px;
				height: 60px;
				padding-top: 8px;
				cursor: pointer;
				transform: translate3d(0, 0, 0);
			}
			path {
				fill: none;
				transition: stroke-dashoffset 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25), all 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
				stroke-width: 25px;
				stroke-linecap: square;
				stroke: $color-third;
				stroke-dashoffset: 0px;
			}
			path#top,
			path#bottom {
				stroke-dasharray: 240px 950px;
			}
			path#middle {
				stroke-dasharray: 10px 10px;
			}
			&.cross path {
				stroke: $grey;
			}
			&.cross path#top,
			&.cross path#bottom {
				stroke-dashoffset: -650px;
				stroke-dashoffset: -650px;
			}
			&.cross path#middle {
				stroke-dashoffset: -115px;
				stroke-dasharray: 1px 220px;
			}
		}

		&.main-navigation {
			.menu-toggle{
				// position: absolute;
				// top: 0px;
				// right: 0px;
			}
			.header-right-menu {
				// position: absolute;
				// top: 0px;
				// right: calc(0px + 70px);
				// height: 100%;
				// padding: 0 !important;

				.language-switcher {
					margin-top: 0px;
					margin-bottom: 0px !important;
					margin-right: 0px;
				}
			}
			// justify-content: flex-end !important;
		}

		div.mobile-pullout {
			position: fixed;
			overflow: scroll;
			overflow-x: hidden;

			display: none;
			flex-wrap: nowrap;
			flex-direction: column;
			justify-content: stretch;
			align-items: center;

			top: 0px;
			right: 0px !important;
			bottom: 0px;
			left: auto;

			animation: slideIn 0.4s ease;

			height: auto;
			width: 100%;
			padding: 0px;
			background: $light-grey;
			z-index: -1;

			.header-left-menu {
				flex: 0 1 50px;
				padding: 0 15px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid $very-light-grey;

				a span.text {
					display: inline;
					width: auto !important;
				}
			}
			.main-menus {
				padding: 15px;
				flex: 1;
				flex-basis: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.menu-item {
					position: relative;
					padding: 6px 0 12px;
					margin-right: 0;

					&:before {
						position: absolute;
						top: -12px;
						left: auto;
						right: auto;
						display: block;
						content: ' ';
						width: 20px;
						height: 1px;
						background-color: $very-light-grey;
					}					
				}

				.menu-header-center-menu-container,
				.menu-header-center-menu-french-container {
					.menu-item:first-child:before {
						display: none;
					}
				}

				a {
					font-size: 20px!important;
					border-top: none!important;
				}

				.login-dropdown {
					z-index: 99;
					.sub-menu{
						@media only screen and (max-width: 1000px) {
							position: absolute!important;
							left: 50%;
							top: 42px!important;
						}
					} 
				}
			}
			.socials {
				flex: 0 1 50px;
				padding: 0 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 1px solid $very-light-grey;

				.social-icon {
					margin: 0 0.5em;
				}
			}

			div, ul, li {
				width: 100%;
				height: inherit;
				position: relative;
			}

			li {
				justify-content: center;
				opacity: 0;
				animation: slideIn 0.4s ease;
				animation-fill-mode: forwards;
				margin-bottom: 0.4em;
				flex-wrap: wrap;
				margin-left: 0;
				margin-bottom: 1.125em;
			}

			ul li.menu-item-has-children:hover .sub-menu {
				display: none;
			}

			#menu-header-center-menu li > a {
				font-size: 20px !important;
				border-top: none !important;

				#{$hover} {
					color: $purple !important;
				}
			}

			.sub-menu {
				position: relative !important;
				width: 100%;
				flex-wrap: wrap;
				margin-top: .5em;
				top: 0px !important;

				& > li {
					margin-right: 0;
				}

				& > li > a {
					font-size: 14px !important;
					font-weight: 500;
				}
			}

			.header-center-menu {
				margin-top: -3em;
			}

			.overlay-trigger {
				display: none;
			}
			.overlay-trigger.mobile {
				display: flex;
				color: $color-third;
				position: relative;
				right: 0;
			}

			.white-bottom {
				border-bottom: 1px solid $very-light-grey;
				height: 75px;
			}

			.header-right-menu {
				justify-content: center;
				padding-top: 0.8em;
				position: fixed;
				z-index: 0;
				bottom: 0.8em;
				left: 0px;
				border-top: 2px solid rgba(white, 0.5);

				i {
					font-size: 1.5em;
					margin: 0 0.2em;
				}
			}

			.header-right-menu li > a {
				@include font-size(20px);
			}

			.menu-icons {
				margin-top: 5px;
			}
		}

		#toggle-right {
		  display: none;
			color: $color-main !important;
			// &:checked ~ div.mobile-pullout {
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// }
			// &:checked ~ div.menu-icons {
		  //   right: 0px !important;
			// 	text-align: center;
			// }
			// &:checked + .menu-toggle {
		  //   right: 20px;
			// 	color: $color-main;
			// }
		}
	}

}
